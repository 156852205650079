.auth .swiper {
  flex: 1;
}

.auth .swiper-wrapper {
  @apply h-[100vh]
}

.auth .swiper-slide {
  @apply w-full flex justify-center items-center
}

.circle {
  @apply w-8 h-8 rounded-[50px] border-2 border-white flex justify-center items-center transition-[background,color] duration-500 ease-in-out
}

.dot {
  @apply w-3 h-3 rounded-[50px] transition-[background,color] duration-75
}

.line {
  @apply w-[1.5px] h-11 bg-white rounded-sm my-2 relative
}

.subline {
  @apply absolute left-0 right-0 top-0 bg-orange h-0 transition-[height] duration-300
}

.sublineActive {
  @apply h-full
}