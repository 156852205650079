.mainModal-enter {
  /* transform: scale(0); */
  opacity: 0;
  z-index: 1;
}
.mainModal-enter-active {
  /* transform: scale(1); */
  opacity: 1;
  transition: opacity .3s, transform .3s;
}
.mainModal-exit {
  /* transform: scale(1); */
  opacity: 1;
}
.mainModal-exit-active {
  opacity: 0;
  /* transform: scale(0); */
  transition: opacity .3s, transform .3s;
}