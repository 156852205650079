@import url("//fonts.googleapis.com/css2?family=Rubik:wght@400;500;600;700&display=swap");
@tailwind base;
@tailwind components;
@tailwind utilities;


@layer components {
  .label-base {
    @apply text-darkGray text-small;
  }

  .inputOutlined {
    @apply outline-none rounded-lg border-lightGray hover:border-gray focus:border-gray font-normal placeholder:font-light placeholder:text-gray
    border-[1px] border-solid px-3 py-4 text-normal text-blue bg-white disabled:text-gray disabled:hover:border-lightGray;
  }

  .btnOutlined {
    @apply focus:outline-darkOrange border-[1px] border-gray bg-white px-2 py-3 rounded-lg duration-100 font-normal hover:bg-lightOrange
    hover:text-orange active:bg-activeOutlinedOrange hover:border-orange text-gray;
  }

  .btnFilled {
    @apply focus:outline-darkOrange rounded-lg duration-100 px-2 py-3 text-white bg-orange font-normal
    hover:bg-darkOrange active:bg-activeFilledOrange;
  }

  .btnAddContent {
    @apply focus:outline-darkOrange rounded-lg w-14 h-12 shadow-[0px_0px_20px_rgba(238,111,66,0.4)]
    text-2xl bg-orange hover:bg-darkOrange text-white duration-150 leading-[0];
  }

  .tableRows {
    @apply [&>p]:py-4 [&>p]:whitespace-nowrap
    [&>p]:overflow-hidden [&>p]:text-ellipsis [&>p:not(:last-child)]:pr-4;
  }

  .tableRowLine {
    @apply col-span-6 w-full h-[1px] bg-lightGray;
  }

  .tableBtns {
    @apply text-right
    [&>button>svg]:h-[20px] [&>button]:h-fit [&>button>svg]:text-gray [&>button>svg:hover]:text-blue;
  }

}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  list-style: none;
}

* ::after,
::before {
  box-sizing: border-box;
}

body {
  background-color: #f8f8f8;
  font-family: "Rubik", sans-serif;
  font-size: 16px;
  @apply text-blue;
}

::-webkit-scrollbar {
  width: 5px;
  height: 5px;
}

.timeInput {
  ::-webkit-calendar-picker-indicator {
    filter: invert(1);
    color: #ebe8e8;
  }
}

/* Track */
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px rgb(237, 237, 237);
  border-radius: 10px;
  /* @apply shadow-orange */
}

/* Handle */
::-webkit-scrollbar-thumb {
  @apply bg-lightGray rounded-xl;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  @apply bg-darkGray;
}

input[type="date"]::-webkit-calendar-picker-indicator {
  cursor: pointer;
  border-radius: 4px;
  margin-right: 2px;
  opacity: 0.6;
  filter: invert(0.8);
}

input[type="date"]::-webkit-calendar-picker-indicator:hover {
  opacity: 1;
}

.title {
  @apply text-xl text-blue font-medium;
}

input {
  height: 48px;
}

button {
  height: 48px;
}

[data-title]:hover:after {
  opacity: 1;
  transition: all 0.1s ease 0.5s;
  visibility: visible;
}

[data-title]:after {
  content: attr(data-title);
  position: absolute;
  top: 100%;
  left: -15px;
  white-space: nowrap;
  opacity: 0;
  z-index: 99999;
  visibility: hidden;
  @apply bg-lightBlue text-blue font-medium rounded-md p-3 text-xs shadow-md;
}

[data-title] {
  position: relative;
}

/* select */
select {
  -webkit-appearance: none;
  appearance: none;

  background-image: url("//d1k5j68ob7clqb.cloudfront.net/processed/meta/U58o0Y2xeB7uxwNjS8.png");
  background-size: 15px;
  background-repeat: no-repeat;
  background-position: calc(100% - 14px) center;
  padding-right: 38px !important;
}

select:invalid {
  @apply text-gray
}

.MuiButtonBase-root {
  @apply h-fit;
}

.MuiTouchRipple-root {
  display: none !important;
}

.MuiMenuItem-root {
  font-size: 16px !important;
}
